/* eslint-disable  */
/* .messenger{
    display: flex;
    height: calc(100vh - 60.78px);
}


.chatBoxWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: white;
    height: 100%;
}

.chatBoxTop{
     height: 75vh;
     overflow-y: scroll;
     padding-right: 10px;
     background: white;

}
.chatBoxBottom{
margin-top: 10px;
display: flex;
justify-content: space-between;
align-items: center;
}
.chatMessageInput{
   flex: 1;
   height: 90px;
   margin-right: 20px;
   padding: 1rem;
}

.createChatArrea{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.createChatArrea button{
    background: var(--primary);
    color: white;
    padding: 8px 20px;
    border-radius: 4pc;
    margin-top: 7px;
}

.buttonContainer{
    display: flex;
    align-items: center;
}
.buttonContainer .chatMessageInput{
   border: 1px solid var(--primary);
}
.buttonContainer .button{
    background: var(--primary);
}



@media screen and (max-width: 500px) {
    .chatConversation{
        display: block;
    }
    .chatBox{
        display: none;
    }

    .backArrow{
        background: rgb(253, 141, 141);
        width: 50px;
        height: 30px;
        border-radius: 30%;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .7rem;

    }

    .chatMessageInput{
        flex: 1;
        height: 50px;
        margin-right: 20px;
        padding: .4rem;
        font-size: .7rem;
        margin-bottom: 10px;
        border: 1px solid rgb(175, 255, 164);
     }
     .button{
         font-size: .8rem;
         border-radius: .5vw;
         font-weight: bold;
     }

} */

/* /////  THE MESSAGE ADMIN STYLING SGHIOULD BE DHERE. */
.MessageDetailed .messageShowCase{
    background: white;
    /* padding: 1rem; */
    margin-top: .5rem;
    margin-bottom: 1rem;
    box-shadow: var(--shadow);
}
.MessageDetailed .messageShowCase h2{
    background: var(--primaryColor);
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
}
.MessageDetailed .messageShowCase .itemsConainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.MessageDetailed .messageShowCase .itemsConainer .icon{
    font-size: 1.5rem;
}




  
  /* .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 700px;
  } */
 
  
  /* === CONVERSATIONS === */
 
  
  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }
  
  
  

  
  .chat {
    /* width: calc(65% - 85px); */
    width: 100%;
    position: relative;
    height: calc(80vh - 100px);;
  }
  
  .header-chat {
    background-color: #FFF;
    height: 90px;
    box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
    display:flex;
    align-items: center;
  }
  
  .chat .header-chat .icon {
    margin-left: 30px;
    color:#515151;
    font-size: 14pt;
  }
  
  .chat .header-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }
  
  .chat .header-chat .right {
    position: absolute;
    right: 40px;
  }
  
  .chat .messages-chat {
    padding: 25px 35px;
    padding-bottom: 80px;
    height: 100%;
    /* background-color: red; */
    overflow-y: scroll;
  }
  
  .chat .messages-chat .message {
    display:flex;
    /* align-items: flex-start; */
    flex-direction: row;
    margin-bottom: 8px;
  }
  
  .chat .messages-chat .message .photo {
      display: block;
      width: 45px;
      height: 45px;
      background: #E6E7ED;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
  }
  
  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #ffffff;
    padding: 6px  10px;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text-only {
    margin-left: 45px;
  }
  
  .time {
    font-size: 10px;
    color:rgb(153, 153, 153);
    margin-bottom:10px;
    margin-left: 85px;
  }
  
  .response-time {
    float: right;
    font-size: 0.7rem;
    color: rgb(153, 153, 153);
    margin-right: 40px !important;
  }
  
  .response {
    float: right;
    margin-right: 0px !important;
    margin-left:auto; /* flexbox alignment rule */
  }
  
  .response .text {
    background-color: #e3effd !important;
  }
  
  .footer-chat {
    width: 100%;
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    overflow: hidden;
    align-items: center;
    
  }
  
  .chat .footer-chat .icon {
    /* margin-left: 30px; */
    color:#C0C0C0;
    font-size: 14pt;
  }
  
  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }
  
  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }
  
  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }
  
  .write-message {
    border:none !important;
    width:80%;
    flex: 1;
    height: 50px;
    /* margin-left: 20px; */
    padding: 10px;
  }
  
  .footer-chat *::-webkit-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }
  .footer-chat input *:-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }
  .footer-chat input *::-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
    margin-left:5px;
  }
  .footer-chat input *:-ms-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }
  
  .clickable {
    cursor: pointer;
  }



