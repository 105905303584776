.LoansDashboard {
  display: flex;
  /* grid-template-columns: 5% 95%; */
  /* grid-template-columns: 15% 85%; */
  position: relative;
}
.LoansDashboard .mainPage {
  flex: 1;
  position: relative;
}
.LoansDashboard .loanCardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-row-gap: 2rem;
  justify-content: space-between;
  flex: 1;
  margin-top: 1rem;

}
.LoansDashboard .loanCardContainer .card {
  padding: 10px;
  color: white;
  width: 90%;
}
.LoansDashboard .loanCardContainer .card .iconText{
display: flex;
align-items: center;
gap: 10px;
}

.LoansDashboard .card .iconCover {
  /* width: 60px;
  height: 40px; */
  /* background: red; */
  border-radius: 4px;
  padding: 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoansDashboard .card .icon {
  font-weight: bold;
  font-size: 1rem;
  color: white;

}
.LoansDashboard .card .title {
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.2rem 0rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.LoansDashboard .card .subtitle {
  font-weight: bold;
  font-size: 0.7rem;
  /* color: #bbbbbb; */
}
.LoansDashboard .card .value {
  font-weight: bold;
  font-size: 2.3rem;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.LoansDashboard .LoanSideBar {
  /* width: 100px; */
  /* background-color: var(--primary); */
  /* background-color: rgb(0, 16, 85); */
  /* border-right: 1px solid rgb(224, 224, 224); */
  background: #f4f4f4;

  color: black;
  height: 100vh;
  position: relative;
  padding: 15px;
  width: 16%;
  font-size: .8rem;
}
.LoansDashboard .LoanSideBar .profile {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.LoansDashboard .LoanSideBar .profile button {
  width: 100%;
  border: none;
  padding: 0.4rem 0rem;
  height: 50px;
  /* background-color: var(--primary); */
  background-color:  rgb(1, 141, 99);
  color: #fff;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  cursor:auto;
}
.LoansDashboard .LoanSideBar .menuList {
  margin-top: .5rem;
  background: white;
  /* height: 100%; */
  height: 87vh;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.LoansDashboard .LoanSideBar .menuList .menu {
  display: flex;
  /* justify-content: center; */
  align-items: center;
color: rgba(0, 0, 0, 0.671);
  margin-top: .2rem;
  font-size: 0.9rem;
  font-weight: 700;
  padding: .4rem .4rem;
  transition: ease-in-out all .3s;
  border-radius: 4pc;
}
.LoansDashboard .LoanSideBar .menuList .menu:hover {
  background: #797979;
  color: white;


}
.LoansDashboard .LoanSideBar .menuList .menu:hover .icon {
  
  color: white !important;

}
.LoansDashboard .LoanSideBar .menuList .menu .icon {
  margin-right: .7rem;
  font-size: 1.2rem;
  width: 20px;
  color: black !important;
}

.LoansDashboard .mainPage {
  height: 100vh;
  overflow-y: hidden;
  flex: 1;
}
.LoansDashboard .mainPage .DashboardContent {
  height: 100vh;
  overflow-y: scroll;
}
.LoansDashboard .mainPage .topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  /* background-color: var(--primary); */
  background-color:white;
  color: gray;
  box-shadow: 1px 2px 4px rgba(226, 226, 226, 0.664);
}
.LoansDashboard .mainPage .topBar .menus ul {
  display: inline-block;
  margin-left: 10px;
}
.LoansDashboard .mainPage .topBar .menus ul li {
  list-style: none;
  margin-left: 10px;
  
}
.LoansDashboard .mainPage .topBar .icon_nd_search {
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
}
.LoansDashboard .mainPage .topBar .menus li {
  display: inline-block;
  margin-left: 10px;
}
.LoansDashboard .mainPage .topBar .prodileInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.LoansDashboard .mainPage .topBar .prodileInfo .icon {
  font-size: 1.4rem;
  margin: 0px 10px;
}
.LoansDashboard .mainPage .topBar .prodileInfo .userImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px 10px;
  background: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoansDashboard .mainPage .cardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.LoansDashboard .mainPage .cardContainer .card {
  border: 0.6px solid rgba(51, 153, 139, 0.384);
  /* width: 80%; */
}
.LoansDashboard .mainPage .cardContainer .card img {
  width: 50px;
}
.LoansDashboard .mainPage .cardContainer .imgCover {
  width: 50px;
  height: 50px;
  background: rgb(51, 153, 139);
  margin: 0.5rem 0rem;
}
.LoansDashboard .mainPage .cardContainer .section1 {
  padding: 1rem 1rem;
}
.LoansDashboard .mainPage .cardContainer .section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--success);
  padding: 0.4rem 0.5rem;
  color: white;
  font-weight: bold;
  /* padding: 0rem .4rem; */
}
.LoansDashboard .chart_nd_deductions {
  display: grid;
  grid-template-columns: 65% 35%;
  margin: 1rem 0rem;
  padding-top: 1rem;
}
.LoansDashboard .chart {
  background-color: white;
  padding-top: 1rem;
}
.LoansDashboard .deductions {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.LoansDashboard .deductions .card {
  background-color: white;
  width: 90%;
  padding: 0.3rem 0rem;
  margin: 0 auto;
  box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
}
.LoansDashboard .deductions .card .icon {
  font-weight: bold;
  font-size: 1.8rem;
}
.LoansDashboard .deductions .card .title {
  font-weight: bold;
  padding-top: 0.5rem;
}
.LoansDashboard .deductions .card .subTitle {
  font-size: 0.9rem;
  color: var(--grayDeep);
  padding: 0.2rem 0rem;
}
.LoansDashboard .deductions .card .value {
  font-size: 0.9rem;
  padding: 0.2rem 0rem;
  font-weight: bold;
}

/* table style */
.LoansDashboard .usersDisplay {
  display: grid;
  grid-template-columns: 65% 35%;
}
.LoansDashboard .usersDisplay .usersList {
}
.LoansDashboard .usersDisplay .usersList .usersTable {
}
.LoansDashboard .usersDisplay .usersList .usersTable table {
  border: 0px solid white;
  box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
}
.LoansDashboard .usersDisplay .usersList .usersTable table thead th {
  border: 0px solid white;
}
.LoansDashboard
  .usersDisplay
  .usersList
  .usersTable
  table
  tbody
  tr:nth-child(odd) {
  background-color: white;
}


.LoansDashboard .loansDashboardFooter{
  background-color: rgb(1, 141, 99);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.loansDashboardFooter .monthlyLoanSumary{
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 1rem;
  padding: .5rem 1rem;
}