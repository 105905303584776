.App {
background: #f4f4f4;
}

.button {
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.4vw;
    color: var(--whiteColor);
    border: none;
    background: var(--primaryColor);
  }

  .backbtn{
    background: rgb(114, 114, 114);
    padding: 1px 10px;
    margin-top: .2rem;
    border-radius: 4pc;
    color: white;
  }