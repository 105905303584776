.HirePurchaseDashboard .hirePurchaseContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
}
.HirePurchaseDashboard .hirePurchaseContainer .card {
  padding: 1rem;
}
.HirePurchaseDashboard .hirePurchaseContainer .card .icons {
  display: flex;
  justify-content: space-between;
}
.HirePurchaseDashboard .hirePurchaseContainer .card .icons .cover {
  background: rgb(166, 187, 255);
  padding: 2px 10px;
  border-radius: 0.2vw;
}
.HirePurchaseDashboard .hirePurchaseContainer .card1 {
  background: var(--primary);
  color: white;
}
.HirePurchaseDashboard table {
  border: 0px solid white;
  margin: 1rem 0rem;
  box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
}
.HirePurchaseDashboard thead th {
  border: 0px solid white;
}
.HirePurchaseDashboard tbody tr:nth-child(odd) {
  background-color: white;
}

.ProductDetailed .backBtn {
  background: gray;
  padding: 0rem 0.5rem;
  border-radius: 4pc;
  color: white;
}
.ProductDetailed .imageAndInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.ProductDetailed .imageAndInfo .imageSection {
  background: white;
}
.ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider {
  height: 400px;
}
.ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider img {
  height: 400px;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .slide .legend {
  display: none;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper {
  margin: 0px;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper img {
  height: 50px;
}
.ProductDetailed .imageAndInfo .imageSection .slider img {
  height: 100%;
}
.ProductDetailed .imageAndInfo .smallInfoSection {
  padding: 1rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .productTitle {
  font-weight: 500;
  color: rgb(83, 83, 83);
}
.ProductDetailed .imageAndInfo .smallInfoSection .small_description {
  background: var(--whiteColor);
  padding: 0.5rem;
  border-radius: 0.4vw;
}
.ProductDetailed .imageAndInfo .smallInfoSection .stockAvialable {
  color: var(--success);
}
.ProductDetailed .imageAndInfo .smallInfoSection .outOfStock {
  color: var(--warning);
}
.ProductDetailed .imageAndInfo .smallInfoSection .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .currentPrice {
  font-size: 2rem;
  margin-right: 1rem;
  font-weight: 500;
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .oldPrice {
  text-decoration: line-through;
  color: rgb(170, 170, 170);
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .priceDuration select {
  padding: 15px;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .title {
  padding-top: 0.8rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes div {
  display: flex;
  background: white;
  margin-right: 1rem;
  border: 2px solid var(--primaryColorLite);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .title {
  padding-top: 0.8rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors div {
  display: flex;
  background: white;
  margin-right: 1rem;
  border: none;
  width: 60px;
  height: 40px;
  border-radius: 0.5vw;
  align-items: center;
  justify-content: center;
}
.ProductDetailed .imageAndInfo .smallInfoSection .quantity input {
  width: 20%;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn {
  text-align: end;
  margin-top: 0.5rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn button {
  background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
  border: none;
  padding: 1rem;
  width: 30%;
  color: white;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn .favourite {
  background: rgb(39, 39, 39);
  margin-right: 1rem;
  border: none;
  padding: 1rem 0rem;
  width: 30%;
  color: white;
}
.ProductDetailed .productImages img {
  width: 60%;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
}

.Products .productContainer {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
}
.Products .productContainer .productCard {
  background: #fff;
  height: 250px;
}
.Products .productContainer .productCard .items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}
.Products .productContainer .productCard .items .product-name {
  font-weight: bold;
  padding-top: 0.7rem;
  font-size: 0.7rem;
  text-align: center !important;
  margin-bottom: 5px;
  
}
.Products .productContainer .productCard .image {
  width: 40%;
  height: 60px;
  border: 2px solid green;
}
.Products .productContainer .productCard .image img {
  
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Products .productContainer .productCard .ordered_date {
  font-size: 0.7rem;
  padding: 0.4rem 0rem;
  font-weight: bold;
  color: rgb(167, 167, 167);
}
.Products .productContainer .productCard button {
  border: none;
  background: var(--primary);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 4pc;
}
.Products .productContainer .productCard .editBtn {
  background: #015f39;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Products .productContainer .productCard .editBtn .icon {
  font-size: 1rem;
  color: white;
}
.Products .productContainer .productCard .delBtn {
  background: #960032;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Products .productContainer .productCard .delBtn .icon {
  font-size: 1rem;
  color: white;
}
.Products table {
  border: 0px solid white;
  margin: 1rem 0rem;
  box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
}
.Products thead th {
  border: 0px solid white;
}
.Products tbody tr:nth-child(odd) {
  background-color: white;
}

.Orders .orderContainer {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
}
.Orders .orderContainer .orderCard {
  background: #fff;
}
.Orders .orderContainer .orderCard .items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}
.Orders .orderContainer .orderCard .items .product-name {
  font-weight: bold;
  padding-top: 0.7rem;
  text-align: center;
}
.Orders .orderContainer .orderCard .image {
  width: 100px;
  height: 80px;
}
.Orders .orderContainer .orderCard .image img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.Orders .orderContainer .orderCard .ordered_date {
  font-size: 0.7rem;
  padding: 0.4rem 0rem;
  font-weight: bold;
  color: rgb(167, 167, 167);
}
.Orders .orderContainer .orderCard button {
  border: none;
  background: var(--primary);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 4pc;
}

.OrderDetailed {
  background: white;
}
.OrderDetailed .orderCover {
  display: grid;
  grid-template-columns: 20% 80%;
  margin-bottom: 1rem;
}
.OrderDetailed .product_info {
  box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
  padding: 1rem;
}
.OrderDetailed .user_profile_section {
  z-index: 99;
}
.OrderDetailed .user_profile_section .overlay {
  background: rgba(255, 255, 255, 0.932);
}
.OrderDetailed .profile .items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}
.OrderDetailed .profile .items .profile-name {
  font-weight: bold;
  padding-top: 0.7rem;
}
.OrderDetailed .profile .image {
  border-radius: 50%;
}
.OrderDetailed .profile .image img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid rgba(218, 165, 32, 0.295);
}
.OrderDetailed .profile .ordered_date {
  font-size: 0.7rem;
  padding: 0.4rem 0rem;
  font-weight: bold;
  color: rgb(167, 167, 167);
}
.OrderDetailed .profile button {
  border: none;
  background: var(--primary);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 4pc;
}

.ProcessedOrder table {
  border: 0px solid white;
  margin: 1rem 0rem;
  box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
  -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
}
.ProcessedOrder thead th {
  border: 0px solid white;
}
.ProcessedOrder tbody tr:nth-child(odd) {
  background-color: white;
}/*# sourceMappingURL=hirepurchase.css.map */