


.LoanApplicationDetailed .displaySection{
    display: grid;
    justify-content: space-between;
}
.LoanApplicationDetailed .displaySection .sideOne .item{
    display: block;
    justify-content: space-between;
    margin-right: 1rem;
    margin-bottom: .5rem;
}
.LoanApplicationDetailed .item .name{
    /* margin-right: 1rem; */
    font-size: .9rem;
}
.LoanApplicationDetailed .item .value{
    font-weight: bold;
    font-size: .9rem;
}

.LoanApplicationDetailed .displaySection .sideTwo .item{
    display: block;
    justify-content: space-between;
    margin-right: 1rem;
    margin-bottom: .5rem;
}
.LoanApplicationDetailed .displaySection .sideTwo .item .name{
    margin-right: 1rem;
    font-size: .8rem;
}
.LoanApplicationDetailed .displaySection .sideTwo .item .value{
    font-weight: bold;
    font-size: .9rem;
}
.LoanApplicationDetailed .displaySection .sideTwo .item img{
    width: 150px;
    height: auto;
}

/* .LoansMessages */
.LoansMessages .unread {
    width: 15px;
    height: 15px;
    background: red;
    display: flex;
    border-radius: 50%;
    align-items: center;
    position: relative;
    top: 10px;
    margin-right: 10px;
}


.loanDetailed_profile_container{
    /* height: 200px; */
}
.loanDetailed_profile_container .overlay{
    background: rgba(255, 255, 255, 0.678);
    height: 100%;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; */
}
/* .loanDetailed_profile_container .overlay img{
    width: 100px;
    display: flex;
} */

.LoanApplicationDetailed .profile .items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 1rem 0rem;
}
.LoanApplicationDetailed .profile .profile-name{
    font-weight: bold;
    padding-top: .7rem;
}
.LoanApplicationDetailed .image{
  
    border-radius: 50%;
   
}

.LoanApplicationDetailed .image img{
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 5px solid rgba(218, 165, 32, 0.295);
}

.LoanApplicationDetailed .passportImage{
    width: 200px;
    margin-left: 20px;
    border: 3px dotted rgb(255, 124, 124);
    border-radius: 25px;
}

.LoanApplicationDetailed .idDownloadBtn{
    background: white;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoanApplicationDetailed .passportDownloadBtn{
    background: white;
    /* padding: 10px; */
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoanApplicationDetailed .passportContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* button{
    border: none;
    background: var(--primary);
    color: white;
    font-size: .8rem;
    padding: .2rem 1rem;
    border-radius: 4pc;
} */


.ProfilePage  .inputGroup {
    position: relative;
  }
  .ProfilePage  .inputGroup .icon {
    position: absolute;
    right: 10px;
    top: 12px;
    color: rgb(175, 175, 175);
    cursor: pointer;
  }