.paginations {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3rem;
  padding-top: 4rem;
}
.paginations .numbers {
  display: flex;
}
.paginations .numbers .number {
  padding: 1px 12px;
  margin-right: 0.3rem;
  border-radius: 4pc;
}
.paginations .numbers .number:hover {
  background: var(--primaryColorLite);
  cursor: pointer;
}
.paginations .numbers .active {
  background: var(--primaryColor);
  color: white;
}/*# sourceMappingURL=fundDed.css.map */