.processBtn{
    background: var(--warning) !important;
    margin: 0px 5px;
}
.rejectBtn{
    background: var(--danger) !important;
    margin: 0px 5px;
}
.approvedBtn{
    background: var(--primaryColor) !important;
    margin: 0px 5px;
}
.shippedBtn{
    background: var(--primaryLite) !important;
    margin: 0px 5px;
}

.order_detailed h1{
    font-weight: bold;

}
.order_detailed .value{
    font-weight: bold;
}

.id_card_box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding-top: 10px;
}
.id_card_box .imgCover{
    /* border: 1px solid gray; */
}
.id_card_box .imgCover .imgBox{
    border: 1px dotted gray;
    height: 160px;
    overflow: hidden;
    border-radius: 10px;
    max-width: 300px;
}
img{
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}
.id_card_box .download_btn{
    font-size: 2rem;
}