/* eslint-disable  */

.filesDownload{
    display: flex;
    gap: 10px;
}
.filesDownload .imgCover{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;

}
.filesDownload .imgCover img{
    width: 200px;
    height: 200px;
    cursor: pointer;
}
.filesDownload .pdfCover{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    width: 200px;

}