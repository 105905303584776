/* eslint-disable  */
.loginPage {
  display: grid;
  grid-template-columns: 25% 75%;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginPage .section_one .overlay {
  padding: 1rem;
  background: rgba(0, 89, 255, 0.692);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
}
.loginPage .section_two {
  border-radius: 0rem 0rem 0rem 3rem;
}
.loginPage .section_two .overlay {
  border-radius: 0rem 0rem 0rem 3rem;
  background: rgba(238, 238, 238, 0.966);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  border-radius: 0rem 0rem 0rem 3rem;
}
.loginPage .section_two .overlay .title {
  font-size: 2rem;
  padding-bottom: 1rem;
  color: black;
}
.loginPage .section_two .overlay form {
  width: 500px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.911);
  padding: 2rem;
  border-radius: 20px;
}
.loginPage .section_two .overlay .button {
  border: none;
  margin: 1rem 0rem;
  background: var(--success);
  color: white;
  padding: 0.6rem;
  width: 200px;
  border-radius: 4pc;
}
.loginPage .section_two .overlay .forgot_password {
  color: black;
  text-align: end;
}/*# sourceMappingURL=auth.css.map */