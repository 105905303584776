.SystemUsers .roles{
    width: 100%;
    display: block;
}
.SystemUsers .rols_chips{
    display: flex;
    flex-wrap: wrap;

    font-size: .7rem;
 
    align-items: center;

}
.SystemUsers .rols_chips .rol_chip{
    background: var(--default);
    margin: 6px 2px;
    border-radius: 4pc;
    padding: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
