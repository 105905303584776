/* eslint-disable  */
.LoansNotifications .flex-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin-bottom: .5rem;
    padding: .3rem;
}
.LoansNotifications .flex-items .icon{
/* align-items: center; */
margin-right: .4rem;
}


/* MOBILE STYLES */
@media screen and (max-width: 500px) {
    .LoansNotifications .notif_title{
        font-size: .9rem;
        font-weight: bold;
    }
    .LoansNotifications .notif_msg{
        font-size: .8rem;
    }
    .LoansNotifications .time{
        font-size: .7rem;
        color: rgb(158, 158, 158);
        margin-top: .3rem;
    }
}