.gt-all{
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    
}
.gt-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px; 
}