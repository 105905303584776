.HirePurchaseDashboard{
    .hirePurchaseContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1rem;
        .card{
            padding: 1rem;
          .icons{
              display: flex;
              justify-content: space-between;
              .cover{
                  background: rgb(166, 187, 255);
                //   background: rgb(1, 109, 145);
                  padding: 2px 10px;
                  border-radius: .2vw;
              }
          }
        }
        .card1{
            background: var(--primary);
            color: white;
        }
    }


    table {
        border: 0px solid white;
        margin: 1rem 0rem;
        box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
      }
      thead th {
        border: 0px solid white;
      }
      tbody tr:nth-child(odd) {
        background-color: white;
      }
}

.ProductDetailed {
    .backBtn{
      background: gray;
      padding: 0rem .5rem;
      border-radius: 4pc;
      color: white;
  
    }
    .imageAndInfo {
      display: grid;
  
      // height: 70vh;
      // max-height: 70vh;
      grid-template-columns: repeat(2, 1fr);
      .imageSection {
        background: white;
        .carousel.carousel-slider {
          height: 400px;
          img {
            height: 400px;
          }
        }
        .carousel .slide .legend {
          display: none;
        }
        .carousel .thumbs-wrapper {
          margin: 0px;
          img {
            height: 50px;
          }
        }
        // background: red;
        // height: 70vh;
        .slider {
          // height: 70vh;
          img {
            height: 100%;
          }
        }
      }
      .smallInfoSection {
        padding: 1rem;
        // background: green;
        .productTitle {
          font-weight: 500;
          color: rgb(83, 83, 83);
        }
        .small_description {
          background: var(--whiteColor);
          padding: 0.5rem;
          border-radius: 0.4vw;
        }
        .stockAvialable {
          color: var(--success);
        }
        .outOfStock {
          color: var(--warning);
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          .currentPrice {
            font-size: 2rem;
            margin-right: 1rem;
            font-weight: 500;
          }
          .oldPrice {
            text-decoration: line-through;
            color: rgb(170, 170, 170);
          }
  
          .priceDuration{
          //  width: 100%;
          select{
            padding: 15px;
          }
          }
        }
        .sizeFilters {
          .title {
            padding-top: 0.8rem;
          }
          .sizes {
            display: flex;
            align-items: center;
            margin: 1rem 0rem;
            div {
              display: flex;
              background: white;
              margin-right: 1rem;
              border: 2px solid var(--primaryColorLite);
              // border: 1px solid rgba(204, 204, 204, 0.925);
              width: 40px;
              height: 40px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
            }
            
          }
          // .activeSize{
          //   border: 2px solid var(--primaryColor);
          // }
        }
        .colorFilters {
          .title {
            padding-top: 0.8rem;
          }
          .colors {
            display: flex;
            align-items: center;
            margin: 1rem 0rem;
            div {
              display: flex;
              background: white;
              margin-right: 1rem;
              border: none;
              width: 60px;
              height: 40px;
              border-radius: 0.5vw;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .quantity {
          input {
            width: 20%;
          }
        }
        .cartBtn {
          text-align: end;
          margin-top: 0.5rem;
          button {
            background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
  
            border: none;
            padding: 1rem;
            width: 30%;
            color: white;
          }
          .favourite {
            background: rgb(39, 39, 39);
            margin-right: 1rem;
            border: none;
            padding: 1rem 0rem;
            width: 30%;
            color: white;
          }
        }
      }
    }
    .detailDescription {
    }
    .productImages {
      img {
        width: 60%;
        margin-bottom: 1rem;
        border-radius: 0.6rem;
      }
    }
  }


.Products{
    // .filters{
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    .productContainer{
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(5, 1fr);
        justify-content: center;
        align-items: center;
        .productCard{
            background: #fff;
            height: 250px;
            .items{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem 0rem;
                

                .product-name{
                    font-weight: bold;
                   padding-top: .7rem;
                   font-size: .8rem;
                }
            }
            .image{
                width: 40%;
                height: 70px;
                img{
                  max-height: 70px;
                }
            }
            .ordered_date{
                font-size: .7rem;
                padding: .4rem 0rem;
                font-weight: bold;
                color: rgb(167, 167, 167);
            }
            button{
                border: none;
                background: var(--primary);
                color: white;
                font-size: .8rem;
                padding: .2rem 1rem;
                border-radius: 4pc;
            }
            .editBtn{
                background: #015f39;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: .7rem;
                    color: white;
                

                }
            }
            .delBtn{
                background: #960032;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: .7rem;
                    color: white;
                

                }
            }
        }
    }
   
    table {
        border: 0px solid white;
        margin: 1rem 0rem;
        box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
      }
      thead th {
        border: 0px solid white;
      }
      tbody tr:nth-child(odd) {
        background-color: white;
      } 
}


.Orders {
    .orderContainer{
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(5, 1fr);
        justify-content: center;
        align-items: center;
        .orderCard{
            background: #fff;
            .items{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem 0rem;

                .product-name{
                    font-weight: bold;
                   padding-top: .7rem;
                   text-align: center;
                }
            }
            .image{
                width: 40%;
            }
            .ordered_date{
                font-size: .7rem;
                padding: .4rem 0rem;
                font-weight: bold;
                color: rgb(167, 167, 167);
            }
            button{
                border: none;
                background: var(--primary);
                color: white;
                font-size: .8rem;
                padding: .2rem 1rem;
                border-radius: 4pc;
            }
        }
    }
}

.OrderDetailed{
    background: white;
    .orderCover{
        display: grid;
        grid-template-columns: 20% 80%;
        margin-bottom: 1rem;
    }
    .product_image{
        // width: 300px;
    }
    .product_info{
        box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
        -webkit-box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
        -moz-box-shadow: -4px 3px 26px -5px rgba(209, 209, 209, 0.75);
        padding: 1rem;


    }

    .user_profile_section{
        // height: 100%;
        z-index: 99;
        .overlay{
            background: rgba(255, 255, 255, 0.932);
        }
    }

    .profile{
        // background: #fff;
        .items{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem 0rem;

            .profile-name{
                font-weight: bold;
               padding-top: .7rem;
            }
        }
        .image{
            // width: 20%;
            // height: 100px;
            // background-color: gray;
            border-radius: 50%;
            img{
                border-radius: 50%;
                width: 150px;
                height: 150px;
                border: 5px solid rgba(218, 165, 32, 0.295);
            }
        }
        .ordered_date{
            font-size: .7rem;
            padding: .4rem 0rem;
            font-weight: bold;
            color: rgb(167, 167, 167);
        }
        button{
            border: none;
            background: var(--primary);
            color: white;
            font-size: .8rem;
            padding: .2rem 1rem;
            border-radius: 4pc;
        }
    }
}

.ProcessedOrder{
    table {
        border: 0px solid white;
        margin: 1rem 0rem;
        box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -webkit-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
        -moz-box-shadow: -4px 3px 26px -5px rgba(115, 115, 115, 0.75);
      }
      thead th {
        border: 0px solid white;
      }
      tbody tr:nth-child(odd) {
        background-color: white;
      } 
}