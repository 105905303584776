.RenderHirePurchasePage{
    display: grid;
    /* grid-template-columns: 5% 95%; */
    grid-template-columns: 15% 85%;
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.RenderHirePurchasePage .sideBar{
 background-color: var(--primary);
 border-right: 1px solid rgb(224, 224, 224);
 height: 100vh;
 position: relative;
 padding-left: 10px;
 color: white;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.RenderHirePurchasePage .sideBar .profile{ 
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

}
.RenderHirePurchasePage .sideBar .profile .profileInfo{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
}
.RenderHirePurchasePage .sideBar .profile .profileInfo .profileName{ 
font-size: .7rem;
text-transform: uppercase;
padding-top: 5px;
}
.RenderHirePurchasePage .sideBar .profile button{ 
    width: 100%;
    border: none;
padding: .2rem 0rem;
background-color: white;
color: black;
font-weight: bold;
border-radius: 10px 10px 0px 0px;
}
.RenderHirePurchasePage .sideBar .profile button:hover{ 
    background-color: #dbdbdb;
}
.RenderHirePurchasePage .sideBar .menuList{
    margin-top: 3rem;
}
.RenderHirePurchasePage .sideBar .menuList .menu{
display: flex;
/* justify-content: center; */
align-items: center;
margin-top: .4rem;
cursor: pointer;
font-size: 1rem !important;
padding: 4px 8px;
border-radius: 0px 4pc 4pc 8px;
transition: all ease-in-out .2s;
font-size: 1rem;
}
.RenderHirePurchasePage .sideBar .menuList .menu:hover{
    background-color: white;
    color: black;
    

}
.RenderHirePurchasePage .sideBar .menuList .menu .icon{
margin-right: .5rem;
font-size: 1.1rem;
}

.RenderHirePurchasePage .mainPage{
    height: 100vh;
    overflow-y: hidden;
}
.RenderHirePurchasePage .mainPage .DashboardContent{
    height: 100vh;
    overflow-y: scroll;
}
.RenderHirePurchasePage .mainPage .topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    /* background-color: rgb(1, 109, 145); */
    background-color: #fff;
    
    color: var(--primary);
    box-shadow: 1px 2px 4px rgba(185, 185, 185, 0.557);
    /* box-shadow: var(--shadow); */
}
.RenderHirePurchasePage .mainPage .topBar .menus ul{
    display: inline-block;
    margin-left: 10px;
}
.RenderHirePurchasePage .mainPage .topBar .menus ul li{
  list-style: none;
    margin-left: 10px;
    
}
.RenderHirePurchasePage .mainPage .topBar .icon_nd_search{
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
}
.RenderHirePurchasePage .mainPage .topBar .menus  li{
    display: inline-block;
    margin-left: 10px;
}
.RenderHirePurchasePage .mainPage .topBar .prodileInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2px;
}
.RenderHirePurchasePage .mainPage .topBar .prodileInfo .icon{
font-size: 1.4rem;
margin: 0px 10px;
}
.RenderHirePurchasePage .mainPage .topBar .prodileInfo .userImage img{
width: 20px;
margin: 0px 10px;
}


.RenderHirePurchasePage .mainPage .cardContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
}
.RenderHirePurchasePage .mainPage .cardContainer .card{
    border: .6px solid rgba(51, 153, 139, 0.384);
    /* width: 80%; */
    padding: 5px;
    /* height: 160px; */
   }
   .RenderHirePurchasePage .mainPage .cardContainer .card .value{
    font-size: 3rem;
    text-align: end;
    font-weight: bold;
}
   .RenderHirePurchasePage .mainPage .cardContainer .card .icons{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
   }
   
   .RenderHirePurchasePage .mainPage .cardContainer .card1{
    
       background: rgb(51, 153, 139);
       color: #fff;
   }
   .RenderHirePurchasePage .mainPage .cardContainer .card2{
    
       background: rgb(62, 66, 66);
       color: #fff;
   }
   .RenderHirePurchasePage .mainPage .cardContainer .card3{
/*     
       background: rgb(62, 66, 66);
       color: #fff; */
   }
   .RenderHirePurchasePage .mainPage .cardContainer .card4{
    
       background: var(--danger);
       color: #fff;
   }




.RenderHirePurchasePage .mainPage .cardContainer .section1{
    padding: 1rem 1rem;
}
.RenderHirePurchasePage .mainPage .cardContainer .section2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--success);
    padding: .4rem .5rem;
    color: white;
    font-weight: bold;
    /* padding: 0rem .4rem; */
}
.RenderHirePurchasePage .chart_nd_deductions{
    display: grid;
    grid-template-columns: 65% 35%;
    margin: 1rem 0rem;
    padding-top: 1rem;
}
.RenderHirePurchasePage .chart{
  background-color: white;
  padding-top: 1rem;
}
.RenderHirePurchasePage .deductions{
background-color: #fff;
display: grid;
grid-template-columns: repeat(2, 1fr);
justify-content: center;
align-items: center;
text-align: center;
}
.RenderHirePurchasePage .deductions .card{
background-color: white;
width: 90%;
padding: .3rem 0rem;
margin: 0 auto;
box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
-webkit-box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
-moz-box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
}
.RenderHirePurchasePage .deductions .card .icon{
font-weight: bold;
font-size: 1.8rem;

}
.RenderHirePurchasePage .deductions .card .title{
font-weight: bold;
padding-top: .5rem;

}
.RenderHirePurchasePage .deductions .card .subTitle{
font-size: .9rem;
color: var(--grayDeep);
padding: .2rem 0rem;
}
.RenderHirePurchasePage .deductions .card .value{
font-size: .9rem;
padding: .2rem 0rem;
font-weight: bold;
}


/* table style */
.RenderHirePurchasePage .usersDisplay{
    display: grid;
    grid-template-columns: 65% 35%;

}
.RenderHirePurchasePage .usersDisplay .usersList{
 

}
.RenderHirePurchasePage .usersDisplay .usersList .usersTable{

}
.RenderHirePurchasePage .usersDisplay .usersList .usersTable table{
border: 0px solid white;
box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
-webkit-box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
-moz-box-shadow: -4px 3px 26px -5px rgba(115,115,115,0.75);
}
.RenderHirePurchasePage .usersDisplay .usersList .usersTable table thead th{
border: 0px solid white;
}
.RenderHirePurchasePage .usersDisplay .usersList .usersTable table tbody tr:nth-child(odd){
background-color: white;
}
