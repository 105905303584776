@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

:root {
  /* background: linear-gradient(to bottom,  20%, #015f39 100%); */

  --primaryColor: #01a94b;
  --primaryColorAcent: #015f39;
  --primaryColorLite: rgba(245, 119, 69, 0.432);
  /* --secondaryColor: */
  --whiteColor: white;
  --grayColor: rgb(163, 163, 163);
  --success: rgb(0, 165, 102);
  --successDeep: rgba(0, 148, 91, 0.548);
  --warning: rgb(221, 118, 0);
  --danger: rgb(230, 0, 0);
  --primary: rgb(0, 103, 221);
  --primaryLite: rgb(0, 177, 221);
  --grayColor: rgb(207, 207, 207);
  --default: rgb(121, 121, 121);

  

  --primary-gradient: linear-gradient(to bottom, var(--primaryColor) 20%, var(--primaryColorAcent) 100%)

  
    --weekend: rgba(0, 0, 0, 0);
    --date-light: #f8f9fa;
    --date-primary: #5644c1;
    --date-success: #37d37d;
    --date-primaryLight: #eceaf5;
    --date-primaryTitle: #dbd8f0;
    --date-hover: #262769;
    --date-highlight: #f83854;
    --date-disabled:#c2c1cc;
    --date-width: 260px;
    --date-height: 280px;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* color: rgb(0, 103, 221); */
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important; */
  font-family: "Montserrat", sans-serif !important;
  /* font-optical-sizing: auto; */
}
.Loader {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 9999;

  /* width: 100%;
    height: 100%; */
}
.Loader .overlay {
  background-color: rgba(255, 255, 255, 0.692);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backbtn{
  padding: 1px 10px;
  background: rgba(141, 141, 141, 0.692);
  margin-bottom: 10px;
  border-radius: 4pc;
  cursor: pointer;
  transition: all ease-in-out .3s;
}
.backbtn:hover{
  background: rgba(90, 90, 90, 0.692);

}
.customBtn {
  padding: 5px 10px;
  border: none;
  background: var(--primaryColor);
  margin: 10px;
  margin-top: 1rem;
  color: white;
  border-radius: 4pc;
}
.Loader {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 9999;

  /* width: 100%;
    height: 100%; */
}
.Loader .overlay {
  background-color: rgba(255, 255, 255, 0.692);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 5px;
  font-size: 1.2rem;
  padding-top: 1.5rem;
}

.pagination .page-num{
  padding: 5px 8px;
  cursor: pointer;

  font-weight: 400;
  text-decoration: none;
}
.pagination .page-num:hover{
 
  font-weight: bold;
  background: var(--primaryColorAcent);
  color: white;
  text-decoration: none;
}
.pagination .active{
  background: var(--primaryColor);
  color: white;
  text-decoration: none;
  border-radius: 4pc;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
img{
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
